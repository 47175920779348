<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Items Setup</h3>
      <ul>
        <li>
          <a href="index.html">Items</a>
        </li>
        <li>All Items</li>
      </ul>
    </div>
    <div> -->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Teacher",
        path: "teachers",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "name",
          },
          {
            text: this.$i18n.t("Mobile"),
            value: "mobile",
          },
          {
            text: this.$i18n.t("Email"),
            value: "email",
          },
          {
            text: this.$i18n.t("Gender"),
            value: "gender_translated",
          },
          {
            text: this.$i18n.t("Grades"),
            value: "grade_names",
            sortable: false,
          },
          {
            text: this.$i18n.t("Subjects"),
            value: "subject_names",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Teachers"),
        apiEndPoints: {
          list: "teachers",
          create: "teachers/store",
          edit: "teachers/update",
          delete: "teachers/destroy",
        },
        editedItem: {
          name: "",
          email: "",
          mobile: "",
          gender: "",
          grades_id: [],
          subjects_id: [],
          country_code_id: 187,
        },
        permissions: {
          list: "list-teachers",
          add: "add-teachers",
          edit: "edit-teachers",
          delete: "delete-teachers",
          import: "import-teachers",
        },
        filters: {
          genders: [
            { id: "male", name: this.$i18n.t("male") },
            { id: "female", name: this.$i18n.t("female") },
          ],
          grades: [],
          subjects: [],
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {
    // get grades
    axios
      .get(this.getApiUrl + "/getGrades", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table.filters.grades = response.data.data.grades;
      });
    // get subjects
    axios
      .get(this.getApiUrl + "/teachers/subjects", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.table.filters.subjects = response.data.data;
      });
  },
};
</script>

<style></style>
